.container {
    width           : 100%;
    box-shadow      : 20px 0 20px rgba(0, 0, 0, 0.08);
    height          : 100vh;
    overflow        : scroll;
    display         : block;
    background-color: white;

    a {
        text-decoration: none;
    }

    table {
        width           : 100%;
        background-color: white;

        tr {
            background-color: white;
            border-bottom   : 1px solid var(--light-grey-color);

            &:global(.selected) {
                td {
                    background-color: var(--light-grey-color);
                }
            }

            &:hover {
                td {
                    background-color: var(--light-grey-color);
                }
            }

            td {
                background-color: white;
            }
        }
    }
}