.wrapper {

    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.2s ease;

    .container {
        width: 100%;
        max-width: 800px;
        max-height: 90vh;
        background-color: white;
        display: block;
        top: 0;
        left: 0;
        z-index: 99;
        border-radius: 20px;
        padding: 20px;
        position: relative;
        overflow: scroll;
    }
}

.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    display: block;
    top: 0;
    left: 0;
    z-index: 99;
    opacity: 0;
    transition: all 0.2s ease;
}

.button {
    width: 40px;
    height: 40px;
    background-image: url(../../assets/icons/x_dark.png);
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50px;
    transition: all 0.2s ease;
    cursor: pointer;
    z-index: 99;

    &:hover {
        background-color: var(--grey-color);
    }

}

.show {
    opacity: 1;
}