.container {
    width           : 100%;
    max-width       : 300px;
    display         : block;
    background-color: white;
    box-shadow      : 0 3px 20px rgba(0, 0, 0, 0.08);
    border-radius   : 15px;

    .imageWrapper {
        position: relative;
        width   : 100%;

        label {
            width: 100%;
        }

        input {
            display: none;
        }

        img {
            width                  : 100%;
            height                 : auto;
            border-top-left-radius : 15px;
            border-top-right-radius: 15px;

            &.widthBorderRadiusBottom {
                border-bottom-left-radius : 15px;
                border-bottom-right-radius: 15px;
            }
        }

        .editOverlay {
            width                  : 100%;
            height                 : 100%;
            left                   : 0;
            top                    : 0;
            position               : absolute;
            opacity                : 0;
            background-color       : rgba(0, 0, 0, 0.8);
            display                : flex;
            align-items            : center;
            justify-content        : center;
            text-align             : center;
            border-top-left-radius : 15px;
            border-top-right-radius: 15px;
            transition             : 0.2s ease all;
            color                  : white;
            font-weight            : 700;
            cursor                 : pointer;

            &.show {
                opacity: 1;
            }

            &.widthBorderRadiusBottom {
                border-bottom-left-radius : 15px;
                border-bottom-right-radius: 15px;
            }
        }
    }

    .delete {
        padding                   : 20px;
        width                     : 100%;
        display                   : block;
        background-color          : var(--red-color);
        cursor                    : pointer;
        font-weight               : 700;
        border-bottom-left-radius : 15px;
        border-bottom-right-radius: 15px;
        text-align                : center;
        color                     : white;
    }
}