.container {
    padding         : 25px 30px;
    width           : 100%;
    border-radius   : 15px;
    position        : relative;
    display         : block;
    box-shadow      : 0 3px 20px rgba(0, 0, 0, 0.08);
    background-color: white;

    table {
        width        : calc(100% + 60px);
        margin-left  : -30px;
        margin-bottom: -15px;

        tr {
            td {
                border-bottom: 1px solid lightgrey;
                padding      : 10px;

                &:first-child {
                    padding-left: 30px;
                }

                &:last-child {
                    padding-right: 30px;
                }
            }

            &:last-child {
                td {
                    border-bottom: 0px solid lightgrey;
                }
            }
        }
    }

    &.counter {
        text-decoration: none;
        padding        : 50px;
        transition     : all 0.2s ease;
        position       : relative;
        display        : flex;
        align-items    : center;
        justify-content: center;

        &::before {
            height     : auto;
            content    : '';
            display    : block;
            padding-top: 100%;
            position   : relative;
        }

        :global(.content) {
            display   : inline-block;
            text-align: center;
        }

        h2 {
            font-size: 70px;
        }

        span {
            font-weight: 700;
            color      : gray;
        }

        a {
            position       : absolute;
            bottom         : 15px;
            right          : 20px;
            text-decoration: none;
        }

        &:hover {
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        }
    }
}

.cardContainer {
    display   : grid;
    row-gap   : 20px;
    column-gap: 20px;
}