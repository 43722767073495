.paymentTypeContainer {
    display: flex;
    // border       : 1px solid #d3d3d3;
    // border-radius: 15px;
    margin-top: 20px;

    .dots {
        font-size: 20px;

        &::before {
            content: '····';
            position: relative;
            display: inline-block;
            font-weight: 900;
            font-size: 30px;
            top: 4px;
            letter-spacing: 3px;
        }
    }

    .iconContainer {
        width: 80px;
        background-size: 50px;
        background-position: left center;
        background-repeat: no-repeat;
        background-image: url(../../../assets/icons/Credit_card.png);

        &.card {
            background-image: url(../../../assets/icons/Mastercard.png);
        }

        &.sepa {
            background-image: url(../../../assets/icons/sepa-lastschrift.png);
        }

        &.amex {
            background-image: url(../../../assets/icons/american-express.png);
        }

        &.visa {
            background-image: url(../../../assets/icons/visa.png);
        }

        &.mastercard {
            background-image: url(../../../assets/icons/Mastercard.png);
        }

        &.paypal {
            background-image: url(../../../assets/icons/PayPal-Logo.png);
        }

        &.klarna {
            background-image: url(../../../assets/icons/klarnaLogo.png);
        }

        &.afreshed {
            background-image: url(../../../assets/logo.png);
        }

    }

    .textContainer {
        width: calc(100% - 100px);
        line-height: 30px;
    }
}