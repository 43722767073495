.placeholder {
    margin-bottom: 4px;
}

.editor {
    padding: 0 20px;
}

.wrapper {
    background-color: white;
    box-shadow      : 0 3px 20px rgba(0, 0, 0, 0.08);
    margin-top      : 0px;
    margin-bottom   : 20px;
    border-radius   : 15px;
}

.toolbar {
    border-top-left-radius : 15px;
    border-top-right-radius: 15px;
    padding                : 20px;
    padding-bottom         : 15px;
}

:global(.rdw-option-active) {
    background-color: lightgrey;
}

:global(.rdw-option-wrapper) {
    padding: 10px;
}