.container {

    position: relative;

    .button {
        width                  : 30px;
        height                 : 30px;
        display                : inline-block;
        background-image       : url('../../assets/icons/menu_bullets.png');
        background-size        : 60%;
        background-repeat      : no-repeat;
        background-position    : center center;
        border-top-left-radius : 100%;
        border-top-right-radius: 100%;
        background-color       : transparent;
        padding                : 20px;
        cursor                 : pointer;
        transition             : 0.2s ease all;
    }

    &:hover {

        .button {
            background-color: #EDEFF0;
        }

        .content {
            display: inline-block;
            z-index: 9;
        }
    }

    .content {
        // display            : inline-block;
        display                : none;
        width                  : max-content;
        background-color       : #EDEFF0;
        position               : absolute;
        top                    : 35px;
        right                  : 0;
        padding                : 3px 20px;
        border-radius          : 15px;
        border-top-right-radius: 0px;

        &.show {
            display: inline-block;
        }
    }

    .item {
        width          : 100%;
        display        : block;
        padding        : 10px 0;
        text-decoration: none;
        color          : var(--green-color);
        cursor         : pointer;
    }

}