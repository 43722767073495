.invoiceSelectContainer {
    width: 100%;
    display: flex;

    .left {
        width: 100px;
    }

    .button {

        width: 50px;
        height: 50px;
        display: flex;
        border-radius: 10px;
        background-color: var(--light-grey-color);
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        transition: 0.2 ease all;
        cursor: pointer;

        &:hover,
        &.selected {
            background-color: var(--green-color);
        }
    }

    .right {
        width: calc(100% - 100px);
    }
}