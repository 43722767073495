.container {
    width: 100%;
    max-width: 350px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: fixed;
    bottom: 0;
    right: 10px;
    background-color: white;
    height: 600px;
    max-height: 95%;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .header {
        width: 100%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-color: var(--green-color);
        color: black;
        font-weight: 900;
        padding: 10px;
        position: relative;
        display: block;

        .close {
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            background-image: url(../../assets/icons/x.png);
            background-size: contain;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }

    .content {
        padding: 10px;
        display: block;
        height: 100%;
        overflow: scroll;

        .seperator {
            width: 100%;
            display: block;
            height: 10px;
        }

        .card {
            padding: 20px;
            border: 1px solid #ccc;
            border-radius: 15px;

            .title {
                font-size: 16px;
                margin-bottom: 10px;
                font-weight: 800;
            }
        }

        .bubble {
            display: flex;
            margin-bottom: 5px;

            .message {
                border: 1px solid #ccc;
                border-radius: 10px;
                padding: 5px 10px;
                color: black;
                max-width: 90%;
                width: fit-content;
            }

            &.bot {
                justify-content: flex-start;

                .message {
                    background-color: white;
                    border-bottom-left-radius: 0;
                }
            }

            &.customer {
                justify-content: flex-end;

                .message {
                    background-color: var(--green-color);
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    .footer {
        border-top: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        align-items: center;

        textarea {
            border: none;
            resize: none;
            height: 80px;
            outline: none;
            padding: 10px;
            width: 100%;
        }

        .button {
            padding: 5px 10px;
            background-color: var(--green-color);
            border-radius: 5px;
            font-size: 14px;
            color: black;
            margin-left: 10px;
            margin-right: 10px;

            &:hover {
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
            }
        }
    }
}

.select {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: 0.2s ease all;
    cursor: pointer;

    &:hover,
    &.selected {
        background-color: #ccc;
    }
}