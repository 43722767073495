.timeinput {
    width: 130px;
    border-bottom: 1px solid lightgray;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 15px;

    &.error {
        border-bottom: 1px solid red;
    }

    &.valid {
        border-bottom: 1px solid green;
    }

    input {
        width: 60px;
        padding: 10px;
        border: 0;
        outline: 0;
        text-align: center;
    }
}