.confluenceContainer {
    h2 {
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 10px;
    }

    :global(.confluence-information-macro) {
        display: block;
        border-radius: 15px;
        padding: 14px 20px;
        background-color: var(--green-color);
        margin-top: 40px;

        p {
            margin: 0;
        }
    }
}

.articleField {
    
}