.chatContainer {
    width        : calc(100%);
    margin-bottom: 30px;

    &.self {
        .chatBubble {
            border: 0px solid grey;
        }
    }

    .detailContainer {
        display        : flex;
        width          : 100%;
        justify-content: space-between;
        margin-top     : 5px;
        font-size      : 14px;
    }

    .chatBubble {
        width           : calc(100% - 1px);
        border          : 1px solid grey;
        padding         : 15px;
        background-color: white;
        box-shadow      : 0 0 20px rgba(0, 0, 0, 0.08);
        border-radius   : 15px;
        white-space     : "pre-wrap";

        p {
            margin-bottom: 0 !important;
        }

    }
}

.header {
    width           : 100%;
    position        : sticky;
    top             : 0;
    background-color: white;
    padding         : 15px;
    padding-top     : 20px;
    padding-bottom  : 20px;
    margin-bottom   : 50px;
    display         : flex;
    justify-content : space-between;
    align-items     : center;

    :global(.MuiInput-root) {
        margin-bottom: 0 !important;
    }
}

.contentWrapper {
    width   : 100%;
    height  : 100vh;
    display : block;
    overflow: scroll;
}

.editor {
    position: sticky;
    bottom  : 0;
    width   : 100%;
    display : inline-block;
}