.container {
    width         : 100%;
    display       : block;
    border-bottom : 1px solid lightgrey;
    padding-top   : 10px;
    padding-bottom: 10px;

    &:last-child {
        border-bottom : 0;
        padding-bottom: 0px;
    }

    .typeContainer {
        font-weight: 700;
    }

    .footer {
        width    : 100%;
        color    : grey;
        font-size: 1rem;

        .creatorContainer {
            display: inline-block;
        }

        .dateTimeContainer {
            display     : inline-block;
            padding-left: 20px;
            position    : relative;

            &::before {
                content : '-';
                display : block;
                position: absolute;
                left    : 7px;
            }
        }
    }
}