.container {
    width           : 100%;
    // padding         : 10px 15px;
    // background-color: white;
    // box-shadow      : 0 3px 20px rgba(0, 0, 0, 0.08);
    position        : relative;
    display         : flex;
    border-radius   : 15px;

    h2 {
        // margin: 10px;
    }
}

.button {
    padding      : 0px 20px;
    border-radius: 10px;
    display      : flex;
    align-items  : center;
    font-weight  : 700;
    cursor       : pointer;
    transition   : 0.2s ease all;

    &:hover {
        background-color: var(--light-grey-color);
    }
}

.logoutButton {
    top  : 10px;
    right: 20px;
}