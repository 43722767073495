.dayWrapper {
    width: 100%;
    border-radius: 10px;
    background-color: white;
    border: 1px solid lightgrey;

    .dayContainer {
        display: flex;
        align-items: center;
        width: 100%;

        &.noWorkday {
            opacity: 0.4;
        }

        .dateContainer {
            padding: 10px;
            width: 100px;
            height: 100%;
            font-size: small;
            text-align: center;
            border-right: 1px solid lightgrey;
        }
    }

    .accordion {
        display: none;
        width: 100%;

        &.open {
            display: block;
        }

        .input {
            border-top: 1px solid lightgrey;
            width: 100%;
            padding: 10px;
            display: flex;
            align-items: center;

            :global(.selectFormControl) {
                width: 200px;
                transform: scale(0.8);
                margin: 0 !important;
            }

            :global(.MuiFormControl-root) {
                max-width: 300px;
                transform: scale(0.8);
                margin: 0 !important;
            }
        }
    }
}