.dragndropContainer {
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 120px);
    position: sticky;
    top: 0px;
    left: 0;

    div {
        width: 100%;
        height: 100%;
        padding: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        border: 2px dotted lightgrey;
        background-color: #F2F2F2;
        cursor: pointer;
        transition: 0.2s ease all;
        text-align: center;

        &:hover {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
        }

        p {
            padding: 0;
            margin: 0;
        }
    }
}