.container {
    width: 100%;
    position: relative;
    display: flex;
    margin-bottom: 20px;
    border-radius: 15px;
}

.button {
    padding: 0px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s ease all;

    &:hover {
        background-color: var(--light-grey-color);
    }
}

.logoutButton {
    top: 10px;
    right: 20px;
}