.container {
    padding      : 7px 15px;
    border-radius: 45px;
    display      : inline-block;
    margin-right : 5px;
    width        : max-content;

    &.active,
    &.paid,
    &.done,
    &.charged,
    &.booked,
    &.succeeded {
        background-color: var(--light-green-color);
    }

    &.paused,
    &.issued,
    &.processing,
    &.accepted,
    &.used,
    &.sent,
    &.to_deliver,
    &.out_for_delivery,
    &.needs_checking {
        background-color: var(--yellow-color);
    }

    &.canceled,
    &.failed,
    &.declined,
    &.open,
    &.revoked,
    &.created {
        background-color: var(--red-color);
    }

    &.small {
        height     : 30px;
        min-width  : 30px;
        padding    : 0px 11px;
        align-items: center;
        line-height: 30px;
    }
}