.container {
    width           : calc(240px);
    height          : calc(100vh);
    padding         : 10px 15px;
    background-color: white;
    position        : relative;
    padding         : 20px;
    left            : 0;
    top             : 0;
    position        : fixed;
    box-shadow      : 3px 0 20px rgba(0, 0, 0, 0.03);
    z-index         : 1;
    overflow        : scroll;

    img {
        width        : 90%;
        margin-top   : 0px;
        margin-bottom: 30px;
    }

}

.button {
    padding        : 20px 20px;
    border-radius  : 10px;
    display        : flex;
    align-items    : center;
    font-weight    : 700;
    cursor         : pointer;
    transition     : 0.2s ease all;
    color          : inherit;
    text-decoration: none;
    margin-bottom  : 10px;

    &:hover,
    &.current {
        background-color: var(--light-grey-color);
        color           : inherit;
    }
}

.smallButton {
    padding        : 5px 20px;
    border-radius  : 10px;
    display        : flex;
    align-items    : center;
    font-weight    : 700;
    cursor         : pointer;
    transition     : 0.2s ease all;
    color          : inherit;
    text-decoration: none;
    margin-bottom  : 10px;

    &:hover,
    &.current {
        color: var(--green-color);
    }
}

.buttonContainer {
    border-radius: 10px;
    margin-bottom: 10px;

    &.current {
        background-color: var(--light-grey-color);
        color           : inherit;
        padding-bottom  : 5px;
    }

    .button:last-child {
        margin-bottom: 0px;
    }
}

.logoutButton {
    top  : 10px;
    right: 20px;
}

.logo {
    width           : calc(100% + 40px) !important;
    height          : 70px;
    object-fit      : contain;
    padding         : 20px;
    background-color: white;
    position        : sticky;
    top             : -20px;
    display         : block;
    margin-left     : -20px;
}

.badge {
    min-width       : 20px;
    padding         : 0px 5px;
    height          : 20px;
    font-size       : 10px;
    margin-left     : 5px;
    background-color: var(--green-color);
    color           : white;
    line-height     : 20px;
    text-align      : center;
    border-radius   : 20px;
}